<template>
    <div class="template-page grey-bg">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>

            <div class=" container-expertise">
                <div class="item-expertise" v-for="(b, index) in this.pageData.body.slice(1, this.pageData.body.length)" :key="'expertise'+index">
                    <div class="title-container">
                        <img src="../assets/placeholder-expertise.svg" alt="">
                        <h3>{{ b.summary }}</h3>
                    </div>
                    <div class="description">
                        <p v-html="b.processed"></p>
                    </div>
                </div>
            </div>
        </basic-page>
    </div>
</template>

<script>
    import {
        fetchSingleNode
    } from '../libs/drupalClient'
    import BasicPage from './BasicPage.vue'

    export default {
        components: {
            BasicPage
        },
        name: 'expertise',
        data: () => {
            return {
                currentPageId: "da303e12-c8d8-4f7f-972b-f581c639859f",
                pageData: {},
                fulldata: {},
                bannerimg: null
            }
        },
        methods: {},
        computed: {

        },
        mounted() {
            fetchSingleNode('page', this.currentPageId, {
                    include: ['field_banner_top','field_page_image', 'field_attachments']
                })
                .then(res => {
                    this.pageData = res[0].attributes
                this.fulldata = res[0]
                    if (res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

</style>